import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { getUser, logout } from '../../utils';
import firebase from 'gatsby-plugin-firebase';

export default () => {
  const [user, setUser] = useState();
  const [uid, setUid] = useState();

  useEffect(() => {
    setUid(getUser().uid);
  }, []);

  const logoutButtonTap = () => {
    logout(firebase).then(() => {
      setUid(getUser().uid);
      navigate('/account/login');
    });
  };

  return (
    <div>
      <div className="contact__item">
        <input
          className="form__inputText"
          type="text"
          name="company"
          defaultValue={uid}
        />
      </div>
      <div onClick={logoutButtonTap}>ログアウト</div>
    </div>
  );
};
